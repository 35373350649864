import React, { useEffect, useState, useMemo } from "react";
import { Link, NavLink } from "react-router-dom";
import { liberarOffline, offline } from "../../../api/inspection";
import { searchInmueble, PostGenerateTicket } from "../../../api/ediServices";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  Typography,
  Button,
  MenuItem,
  ListItemIcon,
  useMediaQuery,
  Box,
} from "@mui/material";
import WifiOutlinedIcon from "@mui/icons-material/WifiOutlined";
import WifiOffOutlinedIcon from "@mui/icons-material/WifiOffOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AdaptepTable from "../../components/adaptedTable/AdaptedTable";
import CustomProgress from "../../../components/customProgress/CustomProgress";
import moment from "moment";
import Grid from "@mui/material/Grid";
import CustomAlert from "../../../components/customAlert/CustomAlert";
import {
  getTemplate,
  postAnular,
} from "../../../store/trayInspectionReducer/trayInspectionReducer.action";
import { addTemplateOffline } from "../../../store/trayInspectionReducer/TrayInspectionReducer.reducer";

import useNetwork from "../../../hooks/useNetwork/UseNetwork";
import { useIndexedDB } from "@slnsw/react-indexed-db";
import getData from "../../../helpers/FileParams";
import { postmenuGroupAsync } from "../../../api/menuGroup";
import "./trayInspection.css";

const TrayInspection = () => {
  const networkState = useNetwork();
  const { online } = networkState;

  const matches = useMediaQuery("(max-width:600px)");
  const [message, setMessage] = useState(null);
  const [open, setOpen] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user, offlineStore } = useSelector((state) => state.auth);
  const { template, loading: loadingTemplate } = useSelector(
    (state) => state.trayInspection
  );

  const {
    add: addInspection,
    getAll: getAllInsp,
    deleteRecord: deleteRecordIns,
    getByIndex: getByIndexIns,
  } = useIndexedDB("trayInspections");
  const {
    add: addFormat,
    deleteRecord: deleteRecordTech,
    getByIndex: getByIndexTech,
  } = useIndexedDB("technicalFormatos");
  const {
    add: addInmueble,
    getByIndex: getByIndexInmueble,
    deleteRecord: deleteInmueble,
  } = useIndexedDB("inmuebles");

  const { getAll: getAllMenuGroups, deleteRecord: deleteRecordMenuGroups } =
    useIndexedDB("menuGroups");
  const dispatch = useDispatch();
  const columns = useMemo(() => {
    let arrayHead;
    if (!matches) {
      arrayHead = [
        {
          accessorKey: "codigo",
          header: "Código",
          filterVariant: "autocomplete",
        },
        {
          accessorKey: "inmueble",
          header: "Inmueble",
        },

        { accessorKey: "formato", header: "Nombre de Plantilla" },
        { accessorKey: "responsable", header: "Responsable" },
        {
          accessorKey: "fechaInicioInspeccion",
          enableColumnFilter: false,
          header: "Fecha de Registro",
          Cell: ({ row }) => {
            const { original } = row;

            const date = new Date(original.fechaInicioInspeccion);
            const cincoHorasEnMilisegundos = 5 * 60 * 60 * 1000;
            const horaAnterior = new Date(
              date.getTime() - cincoHorasEnMilisegundos
            );
            return moment(horaAnterior).format("DD/MM/YYYY, h:mm:ss a");
          },
        },
        {
          accessorKey: "fin",
          header: "Fecha finalizado",
          enableColumnFilter: false,
          Cell: ({ row }) => {
            const { original } = row;
            if (
              original.fin !== " " &&
              original.fin !== null &&
              original.fin !== undefined
            ) {
              const date = new Date(original.fin);
              return moment(date)
                .utc("America/Lima")
                .format("DD/MM/YYYY, h:mm:ss a");
            } else {
              return "---";
            }
          },
        },

        {
          accessorKey: "estado",
          header: "Estado",
        },
        {
          accessorKey: "offline",
          header: "Red",

          filterFn: (row, id, filterValue) => {
            //row.getValue(id) if values offline
            return row.getValue(id) === filterValue;
          },

          filterVariant: "select",
          filterSelectOptions: [
            { text: "Todos", value: null },
            { text: "Online", value: false },
            { text: "Offline", value: true },
          ],

          Cell: ({ row }) => {
            const { original } = row;
            return original.offline ? (
              <WifiOffOutlinedIcon sx={{ color: "green" }} />
            ) : (
              <WifiOutlinedIcon sx={{ color: "blue" }} />
            );
          },
        },
      ];
    } else {
      arrayHead = [
        { accessorKey: "codigo", header: "Código" },
        {
          accessorKey: "estado",
          header: "Estado",
        },
        {
          accessorKey: "offline",
          header: "Red",

          filterFn: (row, id, filterValue) => {
            //row.getValue(id) if values offline
            return row.getValue(id) === filterValue;
          },

          filterVariant: "select",
          filterSelectOptions: [
            { text: "Todos", value: null },
            { text: "Online", value: false },
            { text: "Offline", value: true },
          ],

          Cell: ({ row }) => {
            const { original } = row;
            return original.offline ? (
              <WifiOffOutlinedIcon sx={{ color: "green" }} />
            ) : (
              <WifiOutlinedIcon sx={{ color: "blue" }} />
            );
          },
        },
      ];
    }

    return arrayHead;
  }, [matches]);

  const handleClose = () => {
    setOpen(false);
    setMessage(null);
  };
  useEffect(() => {
    if (message) {
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
        setMessage(null);
      }, 3500);
    }
  }, [message]);

  const renderMenu = ({ closeMenu, row }) => [
    <MenuItem
      key={0}
      onClick={() => {
        const { original } = row;
        const {
          idFormato,
          estado,
          id,
          codigo,
          inmueble,
          responsable,
          cliente,
          proveedor,
          tecnicoResponsable,
          tipoInspeccion,
          idCliente,
        } = original;
        if (offlineStore === true) {
          navigate(`/dashboard/technical/${idFormato}`, {
            state: {
              estado,
              id,
              codigo,
              inmueble,
              responsable,
              cliente,
              proveedor,
              tipoInspeccion,
              tecnicoResponsable,
              idCliente,
            },
          });
        } else {
          navigate(`/dashboard/technical/${id}`);
        }
        closeMenu();
      }}
      sx={{ m: 0 }}
    >
      <ListItemIcon>
        <VisibilityIcon />
      </ListItemIcon>
      VER
    </MenuItem>,
  ];

  const renderTopToolbarCustomActions = ({ table }) => {
    const handleOffline = async () => {
      if (!online || offlineStore === true)
        return window.alert("Necesita tener internet o salir del modo offline");
      let answer = window.confirm(
        "Se enviará modo offline solo los que estén liberados y los que estén en ejecución, ¿Desea continuar?"
      );
      if (answer) {
        try {
          setLoading(true);
          for await (const row of table.getSelectedRowModel().flatRows) {
            const { original } = row;

            if (
              original.estado === "EN EJECUCIÓN" &&
              original.offline === false
            ) {
              let inmueble = await getByIndexInmueble(
                "idCliente",
                original.idCliente
              );

              const { success, body } = await offline(original.id);

              if (success) {
                const clientejson = JSON.parse(
                  localStorage.getItem("jsonCliente")
                );
                inmueble = await searchInmueble(
                  original.idCliente === 0 || original.idCliente === undefined
                    ? clientejson.id
                    : original.idCliente,
                  original.idInmueble
                );
                await addInmueble({
                  inmueble: inmueble,
                  idCliente: original.idCliente,
                  idUsuario: user?.countHourMinute,
                  idConfig: original.id,
                });

                await addFormat({
                  formato: body,
                  idUsuario: user?.countHourMinute,
                  idConfig: original.id,
                });

                await addInspection({
                  inspection: { ...row.original, offline: true },
                  idUsuario: user?.countHourMinute,
                  idConfig: original.id,
                });
              }
            }
          }
        } catch (error) {
          console.log(error, "errorr");
        } finally {
          setLoading(false);
          setMessage("Se ha enviado a modo offline");

          dispatch(
            getTemplate({
              idUser: user?.countHourMinute,
              idCliente: user?.IdClienteDefault,
            })
          );
        }
      }
    };

    const handleLiberar = async () => {
      if (!online || offlineStore === true)
        return window.alert("Necesita tener internet o salir del modo offline");
      try {
        let answer = window.confirm(
          "Se recomienda sincronizar , ¿Desea continuar?"
        );
        if (answer) {
          setLoading(true);
          for await (const row of table.getSelectedRowModel().flatRows) {
            const { original } = row;

            const { success } = await liberarOffline(original.id);

            if (success) {
              const formatData = await getByIndexTech("idConfig", original.id);
              const inspectioData = await getByIndexIns(
                "idConfig",
                original.id
              );
              const inmuebleData = await getByIndexInmueble(
                "idConfig",
                original.id
              );
              /*aca depende de que hay en la variable y borramos*/
              const dataMenu = await getAllMenuGroups();
              const filterMenu = dataMenu.filter(
                (e) => e.idConfig === original.id
              );

              for await (const parameter of filterMenu) {
                await deleteRecordMenuGroups(parameter.id);
              }
              if (inmuebleData) await deleteInmueble(inmuebleData.id);
              if (formatData) await deleteRecordTech(formatData.id);
              if (inspectioData) await deleteRecordIns(inspectioData.id);
            }
          }

          dispatch(
            getTemplate({
              idUser: user?.countHourMinute,
              idCliente: user?.IdClienteDefault,
            })
          );
          setMessage("Se ha liberado el modo offline");
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    const handleSynchronize = async () => {
      
      
      if (!online || offlineStore === true)
        return window.alert("Necesita tener internet o salir del modo offline");

      let answer = window.confirm(
        'Antes de  sincronizará verifique que el cliente este relacionado con unidad mantenimiento y que tipo de solicitud ya que modificaremos el campo "Ticket" al sincronizar  ¿Desea Continuar?'
      );

      if (answer) {
        try {
          setLoading(true);
          for await (const row of table.getSelectedRowModel().flatRows) {
            const { original } = row;
            const dataMenu = await getAllMenuGroups();

            const filterMenu = dataMenu.filter(
              (e) => e.idConfig === original.id
            );
            let count = 0;
            for await (const parameter of filterMenu) {
              let arrobjCopy = [];
              parameter.campo.parametros.forEach((parametro) => {
                if (
                  typeof parametro.fieldValue === "object" ||
                  parametro.fileDb?.uuidFileName
                ) {
                  arrobjCopy.push({ ...parametro });
                }
              });
              const param = await getData(arrobjCopy);
              param.arrCopy.forEach((elemento) => {
                const { fileDb, indiceParams, fieldValue } = elemento;
                if (indiceParams !== undefined) {
                  parameter.campo.parametros[indiceParams].fileDb = fileDb;
                  if (typeof fieldValue === "object") {
                    parameter.campo.parametros[indiceParams].fieldValue = "{}";
                  }
                }
              });
              const { TipoResultado, Mensaje } = await PostGenerateTicket({
                IdCliente: original.idCliente,
                IdInmueble: parameter.campo.ubigeoIndex.IdInmueble,
                IdEdificio: parameter.campo.ubigeoIndex.IdEdificio,
                IdNivel: parameter.campo.ubigeoIndex.IdNivel,
                Conclusion: parameter.campo.recomendacion,
                //Codigo: parameter.campo.codigo,
                plataforma: 1,
              });

              if (TipoResultado === 1) {
                console.log(Mensaje, "Mensaje");
                const cliente = JSON.parse(localStorage.getItem("jsonCliente"));
                const { success } = await postmenuGroupAsync({
                  grupoData: {
                    ...parameter.campo,
                    ticket: Mensaje,
                    TokenEDI: localStorage.getItem("accessToken"),
                    idCliente: cliente.id,
                  },
                });
                if (success) {
                  await deleteRecordMenuGroups(parameter.id);
                  count++;
                }
              }
              if (TipoResultado === 2) {
                /*  let msgNoticket = window.confirm(
                  `${Mensaje} Sin embargo se sincronizará los grupos modificando  el campo ticket del cliente ${original},¿Desea continuar?`
                );*/
                const cliente = JSON.parse(localStorage.getItem("jsonCliente"));
                const { success } = await postmenuGroupAsync({
                  //REVISAR ESTO 2:11AM

                  grupoData: {
                    ...parameter.campo,
                    ticket: null,
                    recomendacion: null,
                    solicitaTicket: false,
                    TokenEDI: localStorage.getItem("accessToken"),
                    idCliente: cliente.id,
                  },
                });
                if (success) {
                  await deleteRecordMenuGroups(parameter.id);
                  count++;
                }
                /*} else {
                  return;
                }*/
              }
            }

            if (filterMenu.length !== 0 && filterMenu.length === count) {
              const { success } = await liberarOffline(original.id);
              if (success) {
                const formatData = await getByIndexTech(
                  "idConfig",
                  original.id
                );
                const inspectioData = await getByIndexIns(
                  "idConfig",
                  original.id
                );
                const inmuebleData = await getByIndexInmueble(
                  "idConfig",
                  original.id
                );
                await deleteInmueble(inmuebleData.id);
                await deleteRecordTech(formatData.id);
                await deleteRecordIns(inspectioData.id);
                setMessage("Se ha sincronizado correctamente");
              }
              if (!success) {
                setMessage("no se pudo liberar luego de sincronizar");
              }
            } else {
              setMessage("No se ha sincronizado correctamente");
            }
          }

          dispatch(
            getTemplate({
              idUser: user?.countHourMinute,
              idCliente: user?.IdClienteDefault,
            })
          );
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      }
    };

    const handleAnular = async () => {
      setLoading(true);
      for await (const row of table.getSelectedRowModel().flatRows) {
        const { original } = row;

        dispatch(
          postAnular({
            idInspecion: original.id,
          })
        );
      }
      navigate(0);
      setLoading(false);
    };

    return (
      <div style={{ display: "flex", gap: "0.5rem" }}>
        <Button
          color="success"
          disabled={
            table.getSelectedRowModel().flatRows.length === 0 ||
            offlineStore === true
          }
          onClick={async () => await handleOffline()}
          variant="contained"
          className="headTable-btn_offline"
        >
          Offline
        </Button>
        <Button
          color="info"
          disabled={
            table.getSelectedRowModel().flatRows.length === 0 ||
            offlineStore === true
          }
          onClick={async () => await handleLiberar()}
          variant="contained"
          className="headTable-btn_liberar"
        >
          Liberar
        </Button>
        <Button
          color="info"
          disabled={
            table.getSelectedRowModel().flatRows.length === 0 ||
            offlineStore === true
          }
          onClick={async () => await handleSynchronize()}
          variant="contained"
          className="headTable-btn_sync"
        >
          Sincronizar
        </Button>
        {user?.ListRoles.length === 1 &&
          !user?.ListRoles.find((e) => e === 18)(
            <Button
              variant={"contained"}
              component={Link}
              to={"../inspections/config"}
              className="headTable-btn_sync"
              color="success"
            >
              Nueva Inspección
            </Button>
          )}
        {user?.ListRoles.length > 1 && (
          <Button
            variant={"contained"}
            component={Link}
            to={"../inspections/config"}
            className="headTable-btn_sync"
            color="success"
          >
            Nueva Inspección
          </Button>
        )}
        {/* <Button
          color="error"
          onClick={async () => await handleAnular()}
          variant="contained"
          className="headTable-btn_offline"
        >
          ANULAR
        </Button> */}
      </div>
    );
  };

  const renderDetailPanelTable = ({ row }) => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      <Box sx={{ textAlign: "left", fontSize: "0.7rem" }}>
        <span className="TrayInspection-table_txt"> CODIGO: </span>
        {row.original.codigo}
        <br />
        <span className="TrayInspection-table_txt"> CLIENTE: </span>
        {row.original.cliente}
        <br />
        <span className="TrayInspection-table_txt"> INMUEBLE </span>:
        {row.original.inmueble} <br />
        <span className="TrayInspection-table_txt">FORMATO : </span>
        {row.original.formato} <br />
        <span className="TrayInspection-table_txt"> RESPONSABLE :</span>{" "}
        {row.original.responsable} <br />
        <span className="TrayInspection-table_txt">
          {" "}
          FECHA DE INICIO DE INSPECCION:
        </span>
        {moment(new Date(row.original.fechaInicioInspeccion))
          .utc("America/Lima")
          .format("DD/MM/YYYY, h:mm:ss a")}
        <br />
        <span className="TrayInspection-table_txt"> ESTADO:</span>
        {row.original.estado}
      </Box>
    </Box>
  );

  useEffect(() => {
    const jsonClienteId = JSON.parse(localStorage.getItem("jsonCliente"));

    if (offlineStore === true) {
      getAllInsp().then((res) => {
        const filtrado = res.filter(
          (el) => el.idUsuario === user?.countHourMinute
        );
        dispatch(addTemplateOffline(filtrado));
      });
    } else if (user?.countHourMinute) {
      dispatch(
        getTemplate({
          idUser: user?.countHourMinute,
          idCliente: jsonClienteId.id,
        })
      );
    }
  }, [user, online, offlineStore]);

  useEffect(() => {
    const jsonClienteId = JSON.parse(localStorage.getItem("jsonCliente"));
    let valorCodigo = "";
    let valorInmueble = "";
    let valorformato = "";
    let valorResponsable = "";
    let valorEstado = "";
    let valoroffline = null;
    if (offlineStore === true) {
    } else if (user?.countHourMinute) {
      columnFilters.forEach((element) => {
        if (element.id === "codigo") {
          valorCodigo = element.value;
        }
        if (element.id === "inmueble") {
          valorInmueble = element.value;
        }
        if (element.id === "formato") {
          valorformato = element.value;
        }
        if (element.id === "responsable") {
          valorResponsable = element.value;
        }
        if (element.id === "estado") {
          valorEstado = element.value;
        }
        if (element.id === "offline") {
          valoroffline = element.value;
        }
      });

      dispatch(
        getTemplate({
          idUser: user?.countHourMinute,
          idCliente: jsonClienteId.id,
          filtro:
            globalFilter === undefined ||
            globalFilter === "" ||
            globalFilter === null
              ? null
              : globalFilter,
          filtroCodigo: valorCodigo,
          filtroInmueble: valorInmueble,
          filtroFormato: valorformato,
          filtroResponsable: valorResponsable,
          filtroEstado: valorEstado,
          filtroOffline: valoroffline,
        })
      );
    }
  }, [globalFilter, columnFilters]);

  return (
    <>
      <div style={{ width: "100%" }}>
        <Breadcrumbs
          aria-label="breadcrumb"
          className="content1-breadcrumb"
          style={{ margin: "1rem", marginBottom: "1.5rem" }}
        >
          <NavLink
            to="/"
            style={{
              textDecoration: "auto",
              color: "inherit",
              mouseHover: "pointer",
            }}
          >
            Inspecciones
          </NavLink>
          <Typography color="text.primary" className="content2-breadcrumb">
            Bandeja de Inspección
          </Typography>
        </Breadcrumbs>

        {/* <Grid container>
          <Grid item xs={12} style={{ paddingBottom: 20 }}>
            {user?.ListRoles.length === 1 &&
              !user?.ListRoles.find((e) => e === 18)(
                <Button
                  variant={"contained"}
                  component={Link}
                  to={"../inspections/config"}
                  className="trayInspection-button"
                >
                  Nueva Inspección
                </Button>
              )}
            {user?.ListRoles.length > 1 && (
              <Button
                variant={"contained"}
                component={Link}
                to={"../inspections/config"}
                className="trayInspection-button"
              >
                Nueva Inspección
              </Button>
            )}
          </Grid>
        </Grid> */}
        <AdaptepTable
          columns={columns}
          data={template}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
          loading={loadingTemplate}
          renderRowActionMenuItems={renderMenu}
          enableRowSelection={true}
          renderTopToolbarCustomActions={renderTopToolbarCustomActions}
          renderDetailPanelTable={renderDetailPanelTable}
          defaultColumn={{
            minSize: 20, //allow columns to get smaller than default
            maxSize: 9001, //allow columns to get larger than default
            size: 150, //make columns wider by default
          }}
        />
        {open && (
          <CustomAlert
            tipe="info"
            message={message}
            handleClose={handleClose}
          />
        )}
      </div>
      <CustomProgress open={loading} />
    </>
  );
};

export default TrayInspection;
