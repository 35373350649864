import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, NavLink } from "react-router-dom";
import {
  addSection,
  addNameTemplate,
  clearFormat,
  clearIdParams,
} from "../../../store/formatReducer/formatoReducer.reducer";
import { getFormat } from "../../../store/formatReducer/formatReducer.action";

import {
  Card,
  Button,
  TextField,
  Container,
  Breadcrumbs,
  Typography,
  Grid,
} from "@mui/material";

import "./AddFormat.css";
import Format from "./components/format/Format";
import SendModal from "./components/sendModal/SendModal";
import UpdateModal from "./components/updateModal/UpdateModal";

import ProgressLoading from "../../../components/progressLoading/ProgressLoading";
//import MyClient from "../../components/myClient/MyClient";

const AddFormat = () => {
  const dispatch = useDispatch();
  const [template, setTemplate] = useState(null);

  const [aux, SetAux] = useState(true);
  const [aux1, SetAux1] = useState(true);
  const [open1, setOpen1] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);

  const [openModal2, setOpenModal2] = useState(false);
  const [open2, setOpen2] = useState(false);
  const { formato, loading } = useSelector((state) => state.formato);
  const [verCard, setverCard] = useState(false);
  const { idFormat } = useParams();

  const { secciones } = formato;

  const handleClick = () => {
    setverCard(true);
    dispatch(
      addSection({
        nombre: `Nueva Sección  ${secciones.length + 1}`,
        grupos: [],
      })
    );
  };

  const handleSend = () => {
    const jsoncliente = JSON.parse(localStorage.getItem("jsonCliente"));
    

    if (!jsoncliente  || formato.nombre.trim().length === 0) {
      window.alert("Ingrese nombre de la plantilla y un cliente");

      return;
    }

    if (formato.secciones.length === 0) {
      window.alert("Ingrese mínimo una sección");
      return;
    }
    let auxArray = [];
    let listaUnidad=[]

    formato.secciones.forEach((e) => {
      if (e.grupos.length === 0) {
        auxArray.push(false);
      }
    

      e.grupos.forEach((el) => {
         if(el.idUnidadMantenimiento===null || el.idUnidadMantenimiento===undefined){
          listaUnidad.push(false)
         }

        if (el.parametros.length === 0) {
          auxArray.push(false);
        }
      });
    });

    if (auxArray.length > 0 && auxArray.every((e) => e === false)) {
      window.alert("Ingrese mínimo un grupo y un parametro en cada sección");
      auxArray = [];
      return;
    }

     if (listaUnidad.length > 0 && listaUnidad.every((e) => e === false)) {
      window.alert("Ingrese una unidad de mantenimiento en cada grupo");
      auxArray = [];
      return;
    }

    setOpenModal1(!openModal1);
  };

  const handleUpdate = () => {
    if (!formato.cliente || formato.nombre.trim().length === 0) {
      window.alert("Ingrese nombre de la plantilla y un cliente");
      return;
    }

    setOpenModal2(!openModal2);
  };

  const handleChange = (e) => {
    setTemplate(e.target.value);
  };

  if (aux && idFormat) {
    SetAux(false);
    dispatch(getFormat({ idFormat }));
    dispatch(clearIdParams());
  }

  if (aux1 && idFormat === undefined) {
    SetAux1(false);
    dispatch(clearFormat());
  }

  useEffect(() => {
    
    if (template !== null) {
      
      dispatch(addNameTemplate({ template }));
      
    }
  }, [template, dispatch, formato]);

  return (
    <>
      {loading ? (
        <ProgressLoading />
      ) : (
        <>
          <Breadcrumbs
            aria-label="breadcrumb"
            className="content1-breadcrumb"
            style={{ margin: "1rem", marginBottom: "1.5rem" }}
          >
            <NavLink
              to="/"
              style={{
                textDecoration: "auto",
                color: "inherit",
                mouseHover: "pointer",
              }}
            >
              Inspecciones
            </NavLink>

            <Typography
              variant="h6"
              color="text.primary"
              className="content2-breadcrumb"
            >
              Nueva plantilla
            </Typography>
          </Breadcrumbs>
          <Container
            maxWidth={"xl"}
            sx={{ marginTop: "1.5rem" }}
            className="addFormat-section"
          >
            <Card className="card-cabecera">
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <TextField
                    id="outlined-basic"
                    label="Nombre Plantilla"
                    variant="outlined"
                    value={formato?.nombre}
                    onChange={handleChange}
                    sx={{
                      marginTop: "2rem",
                      marginBottom: "2rem",
                      marginLeft: "2rem",
                      width: "50%",
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    type="button"
                    variant="contained"
                    onClick={handleClick}
                    className="addformat-button"
                    color="success"
                    sx={{ marginTop: "2rem", marginBottom: "2rem" }}
                  >
                    <span className="sumCode">&#43;</span>&nbsp;Nueva Sección
                  </Button>
                  {idFormat ? (
                    <Button
                      type="button"
                      variant="contained"
                      className="addformat-update-button"
                      sx={{ marginTop: "2rem", marginBottom: "2rem" }}
                      onClick={handleUpdate}
                    >
                      Actualizar
                    </Button>
                  ) : 
                  (
                    <Button
                      type="button"
                      variant="contained"
                      className="addformat-send-button"
                      sx={{
                        marginTop: "2rem",
                        marginBottom: "2rem",
                        marginLeft: "2rem",
                      }}
                      onClick={handleSend}
                    >
                      Enviar
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Card>

     
              <Card>
                <div className="addFormat-grupos">
                  {secciones &&
                    secciones.map((e, i) => (
                      <Format key={i + "format"} indice={i} element={e} />
                    ))}
                </div>
              </Card>
            
          </Container>
          <SendModal
            setOpenModal1={setOpenModal1}
            openModal1={openModal1}
            open1={open1}
            setOpen1={setOpen1}
          />
          <UpdateModal
            setOpenModal2={setOpenModal2}
            openModal2={openModal2}
            open2={open2}
            setOpen2={setOpen2}
          />
        </>
      )}
    </>
  );
};

export default AddFormat;
