import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postmenuGroupAsync,
  postObtenerMenuAsync,
  getParametrosAsync,
  updateGroupAsync,
} from "../../api/menuGroup";
import { deleteFile } from "../../helpers/azure/azureStorage";

import getData from "../../helpers/FileParams";

export const sendFormat = createAsyncThunk(
  "menuGroupReducer/sendFormat",
  async (dataParams, state) => {
    
    const { menuGroup } = state.getState();

    let arrobjCopy = [];

    menuGroup.campo.parametros.forEach((parametro) => {
      if (
        typeof parametro.fieldValue === "object" ||
        parametro.fileDb?.uuidFileName ||
        parametro.arrayAdjuntos
      ) {
        arrobjCopy.push({ ...parametro });
      }

      if (parametro.subCampos) {
        parametro.subCampos?.forEach((subCampos) => {
          if (
            typeof subCampos.fieldValue === "object" ||
            subCampos.fileDb?.uuidFileName ||
            subCampos.arrayAdjuntos
          ) {
            arrobjCopy.push({ ...subCampos });
          }
        });
      }
    });
    let { arrayError, arrCopy } = await getData(arrobjCopy);

    if (arrayError.length > 0) {
      return Error("Error al subir archivo, revise su conexión a internet");
    }
    return arrCopy;
  }
);

export const sendData = createAsyncThunk(
  "menuGroupReducer/sendData",
  async (dataParams, state) => {
    const cliente = JSON.parse(localStorage.getItem("jsonCliente"));
    const { menuGroup } = state.getState();
    try {
      const res = await postmenuGroupAsync({
        grupoData: {
          ticket: dataParams.ticket,
          ...menuGroup.campo,
          idConfig: menuGroup.idConfig,
          idFormato: menuGroup.idFormato,
          TokenEDI: localStorage.getItem("accessToken"),
          idCliente: cliente.id,
        },
      });

      if (res.success === false) {
        throw Error("Error al enviar el formato");
      }
      return res.success;
    } catch (err) {
      // for await (const parametro of menuGroup.campo.parametros) {
      //   if (parametro.fieldValue === "{}") {
      //     const { fileDb } = parametro;
      //     await deleteFile(fileDb.uuidFileName);
      //   }
      // }

      let errorData =
        err.response?.data?.message || err.response?.data?.title || err.message;
      if (!errorData) {
        errorData = "Something went wrong";
      }
      throw Error(errorData);
    }
  }
);

export const updateData = createAsyncThunk(
  "menuGroupReducer/updateData",
  async (dataParams, state) => {
    try {
      const { menuGroup } = state.getState();

      const res = await updateGroupAsync({
        parametros: menuGroup.campo.parametros,
        ticket: dataParams.ticket,
        idGrupo: dataParams.idGrupo,
        solicitaTicket: menuGroup.campo.solicitaTicket,
        conclusion: menuGroup.campo.conclusion,

        recomendacion: menuGroup.campo.recomendacion,
      });

      return res.success;
    } catch (err) {
      let errorData =
        err.response?.data?.message || err.response?.data?.title || err.message;
      if (!errorData) {
        errorData = "Something went wrong";
      }
      throw Error(errorData);
    }
  }
);

export const postData = createAsyncThunk(
  "menuGroupReducer/postData",
  async (dataParams, state) => {
    try {
      const { menuGroup } = state.getState();
      const { filter } = dataParams;

      const { data } = await postObtenerMenuAsync({
        ...dataParams,
        filter: {
          ...filter,
          idConfig: menuGroup.idConfig,
          idFormato: menuGroup.idFormato,
        },
      });

      return data;
    } catch (err) {
      let errorData =
        err.response?.data?.message || err.response?.data?.title || err.message;
      if (!errorData) {
        errorData = "Something went wrong";
      }
      throw Error(errorData);
    }
  }
);

export const getParametros = createAsyncThunk(
  "menuGroupReducer/getParametros",
  async (dataParams, state) => {
    try {
      const res = await getParametrosAsync(dataParams);

      return res.body;
    } catch (err) {
      let errorData =
        err.response?.data?.message || err.response?.data?.title || err.message;
      if (!errorData) {
        errorData = "Something went wrong";
      }
      throw Error(errorData);
    }
  }
);
