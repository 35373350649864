import AdaptepTable from "../../../../../../components/adaptedTable/AdaptedTable";
import { useMemo, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { IconButton, Button, useMediaQuery, Box } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import { postData } from "../../../../../../../store/menuGroupReducer/menuGroupReducer.action";
import {
  clearData,
  postDataOffline,
} from "../../../../../../../store/menuGroupReducer/menuGroupReducer.reducer";
import { useDispatch, useSelector } from "react-redux";
import useNetwork from "../../../../../../../hooks/useNetwork/UseNetwork";
import { useIndexedDB } from "@slnsw/react-indexed-db";

const TableGroup = ({ setAux, setIdGrupo,setFechaGrupo }) => {
  const { data, loadingData, campo } = useSelector((state) => state.menuGroup);
  const { offlineStore } = useSelector((state) => state.auth);
  const { IdArea, IdEdificio, IdInmueble, IdNivel } = campo?.ubigeoIndex;
  const matches = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();
  const { state } = useLocation();

  const networkState = useNetwork();
  const { getAll: getAllMenuGroups } = useIndexedDB("menuGroups");
  const { online } = networkState;

  const columns = useMemo(() => {
    let arrayHead;
    if (!matches) {
      arrayHead = [
        {
          accessorKey: "registro",
          header: "Registro",
          Cell: ({ row }) => {
            const { original } = row;
            const date = new Date(original.registro);

     
            return moment(date).format("DD/MM/YYYY, h:mm:ss a");
          },
        },
        { accessorKey: "usuario", header: "Usuario" },
        { accessorKey: "archivos", header: "Archivos" },
        {
          accessorKey: "ticketCorrectivo",
          header: "Ticket Correctivo",
          Cell: ({ row }) => {
            const { original } = row;
            const auxTicket = original.ticketCorrectivo ? "Si" : "No";
            return auxTicket;
          },
        },
        {
          accessorKey: "acciones",
          header: "Acciones",
          Cell: ({ row }) => {
            const { original } = row;
            const { idGrupo,registro } = original;
            const handleClick = () => {
    
              setAux(true);
              setIdGrupo(idGrupo);
              setFechaGrupo(registro);

            };
            return (
              <Button onClick={handleClick}>
                <IconButton color="primary" component="label">
                  <VisibilityIcon />
                </IconButton>
              </Button>
            );
          },
        },
      ];
    } else {
      arrayHead = [
        {
          accessorKey: "registro",
          header: "Registro",
          Cell: ({ row }) => {
            const { original } = row;
            const date = new Date(original.registro);
            return moment(date).format("DD/MM/YYYY, h:mm:ss a");
          },
        },

        {
          accessorKey: "acciones",
          header: "Acciones",
          Cell: ({ row }) => {
            const { original } = row;
            const { idGrupo ,registro} = original;
            const handleClick = () => {
           
              setAux(true);
              setIdGrupo(idGrupo);
              setFechaGrupo(registro);

            };
            return (
              <Button onClick={handleClick}>
                <IconButton color="primary" component="label">
                  <VisibilityIcon />
                </IconButton>
              </Button>
            );
          },
        },
      ];
    }

    return arrayHead;
  }, [matches]);

  const renderDetailPanelTable = ({ row }) => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      <Box sx={{ textAlign: "left", fontSize: "0.9rem" }}>
        <span className="TrayInspection-table_txt"> USUARIO : </span>
        {row.original.usuario} <br />
        <span className="TrayInspection-table_txt">ARCHIVOS : </span>
        {row.original.archivos} <br />
        <span className="TrayInspection-table_txt"> TICKET CORRECTIVO : </span>
        {`${row.original.ticketCorrectivo ? "SI" : "NO"}`}
      </Box>
    </Box>
  );

  useEffect(() => {
    if (offlineStore === true) {
      dispatch(clearData());

      getAllMenuGroups().then((menuGroups) => {
        const dataFilter = menuGroups.filter(
          (menuGroup) => menuGroup.idConfig === state.id
        );
        const data = dataFilter.map((menuGroup) => {
          const { campo, registro, id } = menuGroup;
          let countImages = 0;
          campo.parametros.forEach((parametro) => {
            if (parametro.idParametro === 13 && parametro.fieldValue) {
              countImages += parametro.fieldValue.length;
            }
            if (parametro.idParametro === 6 && parametro.fieldValue.name) {
              countImages++;
            }

            if (parametro.idParametro === 14 && parametro.fieldValue) {
              countImages += parametro.fieldValue.length;
            }
            if (parametro.idParametro === 4 && parametro.fieldValue.name) {
              countImages++;
            }
          });

          return {
            usuario: campo.usuario,
            archivos: countImages,
            ticketCorrectivo: campo.solicitaTicket,
            registro: registro,
            idGrupo: id,
          };
        });
        dispatch(postDataOffline(data));
      });
    } else {
      
      dispatch(clearData());
      dispatch(
        postData({
          filter: {
            nombreGrupo: campo.nombreGrupo,
            nombreSeccion: campo.nombreSeccion,
            IdArea: IdArea,
            IdEdificio: IdEdificio,
            IdInmueble: IdInmueble,
            IdNivel: IdNivel,
          },
          page: 0,
          pageSize: 100,
        })
      );
    }
  }, [online, offlineStore, matches]);
  return (
    <div style={{ overflowY: "auto", height: "66%" }}>
      <AdaptepTable
        data={data}
        styleTable={true}
        columns={columns}
        style={{ width: "100%" }}
        defaultColumn={{
          minSize: 20, //allow columns to get smaller than default
          maxSize: 9001, //allow columns to get larger than default
          size: 150, //make columns wider by default
        }}
        loading={loadingData}
        renderDetailPanelTable={matches ? renderDetailPanelTable : null}
      />
    </div>
  );
};

export default TableGroup;
