import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateField } from "../../../store/formatReducer/formatoReducer.reducer";
import {
  Stack,
  Typography,
  Select,
  TextField,
  Box,
  MenuItem,
  InputLabel,
  FormControl,
  Switch,
  FormHelperText,
  Button,
  ImageListItem,
  ImageList,
  Checkbox,
  ListItemText,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import FolderIcon from "@mui/icons-material/Folder";

import { useEffect } from "react";
import "./Element.css";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const styleLabel = {
  marginTop: "1.5rem",
  fontWeight: "bold",
  marginBottom: "1rem",
};

const Element = ({ field, indice }) => {
  const dispatch = useDispatch();
  const [text, setText] = useState(null);
  const [dni, setDni] = useState(null);
  const [file, setFile] = useState(null);
  const [imgPreview, setImgPreview] = useState("");
  const [arrayImgPreview, setArrayImgPreview] = useState([]);

  const [mandatory, setMandatory] = useState(null); //false
  const {
    field_id,
    fieldLabel,
    fieldPlaceholder,
    fieldMandatory,
    fieldEditable,
    fieldValue,
    fieldOptions,
    fileDb,
    idParametro,
  } = field;

  let aux = fieldMandatory;

  const handleChangeText = (e) => {
    if (e.target.value.length === 0) {
      setMandatory(aux === true ? true : false);

      setText(e.target.value);
    } else {
      setMandatory(false);
      setText(e.target.value);
    }
  };

  const handleDni = (e) => {
    if (e.target.value.length >= 9 || isNaN(e.target.value)) {
      return;
    } else {
      setDni(e.target.value);
    }

    if (e.target.value.length === 0) {
      setMandatory(aux === true ? true : false);
    } else {
      setMandatory(false);
    }
  };
  const handleFile = async (e) => {
    const file = e.target.files[0];

    setFile(file);
    if (file.type.slice(0, 5) === "image") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setImgPreview(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleChangeSelect = (e) => {
    if (e.target.value) {
      setMandatory(false);
      setText(e.target.value);
    } else {
      setMandatory(aux === true ? true : false);
    }
  };
  const hanleSwitch = (e) => {
    let eString = e.target.checked.toString();
    dispatch(
      updateField({
        ...indice,
        text: eString,
      })
    );
  };

  const handleFiles = async (e) => {
    let arrayFiles = [];
    let previewFiles = [];
    setArrayImgPreview([]);
    const files = e.target.files;
    for await (const file of files) {
      const reader = new FileReader();
      //fileReaders.push(reader);
      reader.onload = (e) => {
        const { result } = e.target;

        if (result) {
          previewFiles = previewFiles.concat(result);
        }
        if (previewFiles.length === files.length) {
          setArrayImgPreview(previewFiles);
        }
      };
      reader.readAsDataURL(file);
      arrayFiles.push(file);
    }

    dispatch(updateField({ ...indice, text: arrayFiles }));
  };

  const handleArchives = async (e) => {
    let arrayArchives = [];
    const files = e.target.files;
    for await (const file of files) {
      arrayArchives.push(file);
    }

    dispatch(updateField({ ...indice, text: arrayArchives }));
  };

  const handleMultiple = (event) => {
    const {
      target: { value },
    } = event;

    if (value) {
      setMandatory(false);
      setText(value.join(","));
    } else {
      setMandatory(aux === true ? true : false);
    }
  };

  useEffect(() => {
    if (text !== null) {
      setImgPreview("");
      dispatch(updateField({ ...indice, text }));
      setText(null);
    }
    if (dni !== null) {
      setImgPreview("");
      dispatch(updateField({ ...indice, text: dni }));
      setDni(null);
    }
    if (file !== null && text === null && dni === null) {
      dispatch(updateField({ ...indice, text: file }));
      setFile(null);
    }
  }, [text, dni, file]);

  switch (idParametro) {
    
    case 1:
      return (
        <div>
          <div style={styleLabel}>
            <label htmlFor={field_id}>{fieldLabel}</label>
          </div>
          <TextField
            id={field_id}
            variant={"standard"}
            label={fieldPlaceholder}
            disabled={!fieldEditable}
            value={fieldValue}
            error={
              mandatory === null || text === "" ? fieldMandatory : mandatory
            }
            helperText={mandatory ? "Este Campo es Obligatorio" : ""}
            onChange={handleChangeText}
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </div>
      );

    case 11:
      return (
        <div>
          <div style={styleLabel}>
            <label htmlFor={field_id} style={styleLabel}>
              {fieldLabel}
            </label>
          </div>
          <TextField
            id={field_id}
            variant={"standard"}
            label={fieldPlaceholder}
            disabled={!fieldEditable}
            error={
              mandatory === null || dni === "" ? fieldMandatory : mandatory
            }
            helperText={mandatory ? "Este Campo es Obligatorio" : ""}
            onChange={handleDni}
            value={fieldValue}
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </div>
      );

    case 12:
      return (
        <div>
          <div style={styleLabel}>
            <label htmlFor={field_id} style={styleLabel}>
              {fieldLabel}
            </label>
          </div>
          <TextField
            id={field_id}
            variant={"standard"}
            label={fieldPlaceholder}
            disabled={!fieldEditable}
            error={mandatory === null ? fieldMandatory : mandatory}
            helperText={mandatory ? "Este Campo es Obligatorio" : ""}
            inputProps={{ inputMode: "email" }}
            InputLabelProps={{ shrink: true }}
            size="small"
            onChange={handleChangeText}
            value={fieldValue}
          />
        </div>
      );

    case 3:
      return (
        <div>
          <div style={styleLabel}>
            <label htmlFor={field_id} style={styleLabel}>
              {fieldLabel}
            </label>
          </div>
          <TextField
            id={field_id}
            variant={"standard"}
            label={fieldPlaceholder}
            disabled={!fieldEditable}
            value={fieldValue}
            error={mandatory === null ? fieldMandatory : mandatory}
            helperText={mandatory ? "Este Campo es Obligatorio" : ""}
            multiline
            rows={4}
            InputLabelProps={{ shrink: true }}
            size="small"
            onChange={handleChangeText}
          />
        </div>
      );

    case 8:
      return (
        <div>
          <div style={styleLabel}>
            <label htmlFor={field_id} style={styleLabel}>
              {fieldLabel}
            </label>
          </div>
          <TextField
            id={field_id}
            label={fieldPlaceholder}
            disabled={!fieldEditable}
            value={fieldValue}
            error={mandatory === null ? fieldMandatory : mandatory}
            helperText={mandatory ? "Este Campo es Obligatorio" : ""}
            size="small"
            variant="filled"
            onChange={handleChangeText}
          />
        </div>
      );

    case 4:
      return (
        <div>
          <div style={styleLabel}>
            <label htmlFor={field_id} style={styleLabel}>
              {fieldLabel}
            </label>
          </div>
          <Button
            variant="contained"
            size="small"
            component="label"
            style={{ marginBottom: "0.8rem" }}
            disabled={!fieldEditable}
          >
            <input
              id="file"
              style={{ width: "200px" }}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, ,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/pdf,.doc, .docx,handleFile"
              type="file"
              onChange={handleFile}
              hidden
            />

            <FolderIcon />
            {fileDb?.uuidFileName ? "Actualizar" : "Subir"}
          </Button>
          {fieldValue !== "" ? <p>Archivo subido</p> : null}
        </div>
      );

    case 6:
      
      return (
        <div>
          <div style={styleLabel}>
            <label style={styleLabel}>{fieldLabel}</label>
          </div>
          <Button
            variant="contained"
            size="small"
            component="label"
            style={{ marginBottom: "0.8rem" }}
            disabled={!fieldEditable}
          >
            <input
              accept="image/*"
              style={{ width: "200px" }}
              type="file"
              onChange={async (e) => await handleFile(e)}
              hidden
            />
            {fileDb?.uuidFileName ? "Actualizar" : "Subir"}
          </Button>
          <div className="content_img">
            <div className="content-img-image">
              <img
                style={{ height: "10rem" }}
                src={
                  fileDb?.uuidFileName && imgPreview === ""
                    ? `${process.env.REACT_APP_ENPOINT_AZURE_LINK}/${fileDb.uuidFileName}?${process.env.REACT_APP_AZURE_SAS}`
                    : imgPreview
                }
                alt="vista de imagen"
              />

            </div>
          </div>
        </div>
      );

    case 2:
      return (
        <div>
          <div style={styleLabel}>
            <label htmlFor={field_id} style={styleLabel}>
              {fieldLabel}
            </label>
          </div>
          <TextField
            id={field_id}
            variant={"standard"}
            InputLabelProps={{ shrink: true }}
            label={fieldPlaceholder}
            disabled={!fieldEditable}
            value={fieldValue}
            error={mandatory === null ? fieldMandatory : mandatory}
            helperText={mandatory ? "Este Campo es Obligatorio" : ""}
            type="number"
            onChange={handleChangeText}
          />
        </div>
      );

    case 5:
      return (
        <div>
          <div style={styleLabel}>
            <label htmlFor={field_id} style={styleLabel}>
              {fieldLabel}
            </label>
          </div>
          <TextField
            id={field_id}
            label={fieldPlaceholder}
            disabled={!fieldEditable}
            value={fieldValue}
            error={mandatory === null ? fieldMandatory : mandatory}
            helperText={mandatory ? "Este Campo es Obligatorio" : ""}
            InputLabelProps={{ shrink: true }}
            type="date"
            onChange={handleChangeText}
          />
        </div>
      );

    case 10:
      return (
        <div>
          <div style={styleLabel}>
            <label htmlFor={field_id} style={styleLabel}>
              {fieldLabel}
            </label>
          </div>
          <FormControl sx={{ m: 1, minWidth: 220 }}>
            <InputLabel id={field_id}>{fieldPlaceholder}</InputLabel>
            <Select
              labelId={field_id}
              label={fieldPlaceholder}
              disabled={!fieldEditable}
              error={mandatory === null ? fieldMandatory : mandatory}
              onChange={handleChangeSelect}
              value={fieldValue}
            >
              <MenuItem value="" disabled selected>
                Seleccionar...
              </MenuItem>
              {JSON.parse(fieldOptions)?.map((el, i) => (
                <MenuItem value={el} key={i + "option"}>
                  {el}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {mandatory ? "Este Campo es Obligatorio" : ""}
            </FormHelperText>
          </FormControl>
        </div>
      );

    case 9:
      return (
        <div>
          <div style={styleLabel}>
            <label htmlFor={field_id} style={styleLabel}>
              {fieldLabel}
            </label>
          </div>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>No</Typography>
            <AntSwitch
              onChange={hanleSwitch}
              disabled={!fieldEditable}
              checked={fieldValue === "true" ? true : false}
              inputProps={{ "aria-label": "ant design" }}
            />
            <Typography>Si</Typography>
          </Stack>
        </div>
      );

    case 13:
      return (
        <div>
          <div style={styleLabel}>
            <label style={styleLabel}>{fieldLabel}</label>
          </div>
          <Button
            variant="contained"
            size="small"
            component="label"
            style={{ marginBottom: "0.8rem" }}
            disabled={!fieldEditable}
          >
            <input
              accept="image/*"
              style={{ width: "200px" }}
              type="file"
              onChange={async (e) => await handleFiles(e)}
              hidden
              multiple
            />

            {fileDb?.uuidFileName ? "Actualizar" : "Subir"}
          </Button>

          <Box
            sx={{ maxWidth: "500px", maxHeight: "250px", overflowY: "scroll" }}
          >
            <ImageList variant="masonry" cols={3} gap={8}>
              {fileDb?.uuidFileName && arrayImgPreview?.length === 0
                ? fileDb?.uuidFileName?.split(",").map((item) => (
                    <ImageListItem key={item}>
                      <div>
                        <img
                          src={`${process.env.REACT_APP_ENPOINT_AZURE_LINK}/${item}?${process.env.REACT_APP_AZURE_SAS}`}
                          alt={item}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </ImageListItem>
                  ))
                : arrayImgPreview?.map((item) => (
                    <ImageListItem key={item}>
                      <div>
                        <img src={item} alt={item} style={{ width: "100%" }} />
                      </div>
                    </ImageListItem>
                  ))}
            </ImageList>
          </Box>
        </div>
      );

    case 14:
      return (
        <div>
          <div style={styleLabel}>
            <label htmlFor={field_id} style={styleLabel}>
              {fieldLabel}
            </label>
          </div>
          <Button
            variant="contained"
            size="small"
            component="label"
            style={{ marginBottom: "0.8rem" }}
          >
            <input
              id="file"
              disabled={!fieldEditable}
              style={{ width: "200px" }}
              multiple
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, ,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/pdf,.doc, .docx,handleFile"
              type="file"
              onChange={async (e) => await handleArchives(e)}
              hidden
            />
            <FolderIcon />
            {fileDb?.uuidFileName ? "Actualizar Archivos" : "Subir Archivos"}
          </Button>{" "}
          {fileDb?.uuidFileName?.split(",").length} Archivos
          {fieldValue !== "" ? <p>Archivos subidos</p> : null}
        </div>
      );

    case 15:
      return (
        <div>
          <div style={styleLabel}>
            <label htmlFor={field_id} style={styleLabel}>
              {fieldLabel}
            </label>
          </div>
          <FormControl sx={{ m: 1, minWidth: 220 }}>
            <InputLabel id={field_id}>{fieldPlaceholder}</InputLabel>
            <Select
              labelId={field_id}
              label={fieldPlaceholder}
              disabled={!fieldEditable}
              error={mandatory === null ? fieldMandatory : mandatory}
              multiple
              value={fieldValue ? fieldValue.split(",") : []}
              onChange={handleMultiple}
              renderValue={(selected) => selected.join(", ")}
            >
              {JSON.parse(fieldOptions)?.map((el, i) => (
                <MenuItem value={el} key={i + "option"}>
                  <Checkbox checked={fieldValue?.split(",").indexOf(el) > -1} />
                  <ListItemText primary={el} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      );
    default:
      return <div>No existe el parametro</div>;
  }
};

export default Element;
