import * as React from "react";

import { useSelector, useDispatch } from "react-redux";
import { addCampo } from "../../../../../../../store/menuGroupReducer/menuGroupReducer.reducer";

import TreeItem from "@mui/lab/TreeItem";

import MenuGroup from "./menuGroup/MenuGroup";

const MenuSection = ({ section, indice }) => {
  const distpatch = useDispatch();

  const { campo } = useSelector((state) => state.menuGroup);
  const handleClick = (section) => {
    
    distpatch(
      addCampo({
        ...campo,
        nombreSeccion: section,
        nombreGrupo: null,
        parametros: [],
        idFormato: null,
        codigo: null,
        usuario: null,
        idUsuario: null,
        idConfig: null,
      })
    );
  };

  return (
    <TreeItem
      id="demo-positioned-button"
      nodeId={"Menu" + indice}
      label={section.nombre}
      onClick={() => handleClick(section.nombre)}
    >
      <MenuGroup
        groups={section.grupos}
        section={section.nombre}
        indice={indice}
      />
    </TreeItem>
  );
};

export default MenuSection;
