import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TextField, Autocomplete, CircularProgress } from "@mui/material";
import { getListaUnidadMantenimientoAsync } from "../../../../../api/auth";

import { addIdUnidadMantenimientoroup } from "../../../../../store/formatReducer/formatoReducer.reducer";

const AutoCompleteUnidadMantenimiento = ({
  indiceSection,
  indiceGroup,
  grupo,
  bolGroup
}) => {
  const dispatch = useDispatch();
  const [listamantniemitno, setlistaMantenimiento] = useState([]);

  const [loading, setLoading] = useState();
  const [inputValue, setInputValue] = useState(null);

  const listarUnidadMantenimiento = async ( ) => {
    const jsonClienteId = JSON.parse(localStorage.getItem("jsonCliente"));
    
    
   
    setLoading(true);
    const { body } = await getListaUnidadMantenimientoAsync({
      IdCliente: jsonClienteId?.id ,
    });
    if (grupo.idUnidadMantenimiento !== null) {
      let data = body.find((x) => x.id === grupo.idUnidadMantenimiento);
      
      setInputValue(data);
    }

    setlistaMantenimiento(body);
    setLoading(false);
  };
  useEffect(() => {
    listarUnidadMantenimiento();
 
 
  }, [setlistaMantenimiento]);
 
 
 

  return (
    <div className="" style={{margin: "0.3rem" }}>
      <Autocomplete
        disablePortal
        getOptionLabel={(option) => option.nombre}
        size="small"
        value={inputValue}
        onChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          dispatch(
            addIdUnidadMantenimientoroup({
              indiceSection,
              indiceGroup: indiceGroup,
              mantenimiento: newInputValue,
            })
          );
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {" "}
            {option.nombre}{" "}
          </li>
        )}
        loading={loading}
        options={listamantniemitno}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField
    
            sx={{ maxHeight: "10rem" }}
            // error={true}

            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            label="Unidad Mantenimiento"
          />
        )}
      />
    </div>
  );
};

export default AutoCompleteUnidadMantenimiento;
